import React, { useEffect, useContext, useState } from 'react';
import { EyeOutlined, DeleteOutlined, DownloadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal, Typography, Table, message, Pagination, Image, Col, Row } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import Edite from "./Edite";
const { Text, Link } = Typography;




const Index = () => {
  const context = useContext(MahabadContext)
  const [getOrder, setOrder] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [openEdite, setOpenEdite] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const showDrawerEdite = () => {
    setOpenEdite(true);
  };

  const onCloseEdite = () => {
    setOpenEdite(false);
  };
  const onShowCategories = () => {
    setOpenCategories(true);
  };

  const onCloseCategories = () => {
    setOpenCategories(false);
  };
  let columns = [
    {
      title: 'آیدی سفارش',
      dataIndex: 'idd',
      width: 30
    },
    {
      title: 'مشصخات کاربر',
      dataIndex: 'username',
    },
    {
      title: 'لینک سفارش',
      dataIndex: 'link'
    },
    {
      title: 'نوع سفارش',
      dataIndex: 'type',
    },
    {
      title: 'تعداد',
      dataIndex: 'quantity',
    },
    {
      title: 'انجام شده',
      dataIndex: 'donedActions',
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      render: (status) => {
        if (status === "انجام شده") {
          return (<span style={{color:"green"}}>کامل شده</span>)
        } else {
          return (<span style={{color:"blue"}}>در حال انجام</span>)
        }
      },
    },
    {
      title: 'حذف',
      key: '_id',
      dataIndex: '_id',
      value: '_id',
      fixed: 'right',
      width: 50,
      render: (id) => <Tooltip title="حذف سفارش">
        <Button onClick={(e) => {
          setOrder(context.getAllOrders.find(item => item._id == id))
          context.setDeleteAlert(true)
        }} type="primary" danger shape="circle" icon={<DeleteOutlined />} />
      </Tooltip>,
    },
  ];
  const handleDeleteOrder = async (id) => {
    context.setTableLoading(true)
    await axios.delete(`${Config.adminApi}/deleteOrder/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setDeleteAlert(false)
      context.handleGetAllOrders()
      messageApi.open({
        type: 'success',
        content: 'سفارش مورد نظر با موفقیت حذف شد',
      });
      context.setTableLoading(false)
    }).catch((err) => {
      context.setDeleteAlert(false)
      context.setTableLoading(false)
      messageApi.open({
        type: 'error',
        content: 'سفارش حذف نشد',
      });
    })
  }
  const handleConsumeOrder = async (id) => {
    context.setTableLoading(true)
    await axios.get(`${Config.adminApi}/consumeOrder/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setDeleteAlert(false)
      context.handleGetAllOrders()
      messageApi.open({
        type: 'success',
        content: 'سفارش مورد نظر با موفقیت جبران ریزش شد',
      });
      context.setTableLoading(false)
    }).catch((err) => {
      context.setDeleteAlert(false)
      context.setTableLoading(false)
      messageApi.open({
        type: 'error',
        content: 'سفارش جبران ریزش نشد',
      });
    })
  }

  useEffect(() => {
    context.handleGetAllOrders()
    context.handleGetAllServices()
    context.handleGetAllCategoriesForComments()
  }, [])
  const tableProps = {
    loading: context.getLoading
  };
  return (
    <>
      {(openEdite === false) ? (null) : (<Edite order={getOrder} open={openEdite} onClose={onCloseEdite} />)}
      <Modal okType="danger" onOk={(e) => {
        handleDeleteOrder(getOrder._id)
      }} okText="تایید" cancelText="انصراف" style={{ direction: "rtl" }} title="شما در حال حذف سفارش هستید" open={context.getDeleteAlert} onCancel={() => { context.setDeleteAlert(false) }}>
        <p>آیا مطمئنید؟</p>
      </Modal>
      <div style={{ direction: "rtl" }}>{contextHolder}</div>
      <Table
        columns={columns}
        size="middle"
        tableLayout="auto"
        style={{ direction: "rtl" }}
        scroll={{
          x: 100
        }}
        loading={context.getTableLoading}
        pagination={false}
        dataSource={(context.getAllOrders !== null) ? context.getAllOrders : []}
      />
      <div style={{ marginTop: "15px" }}><Pagination onChange={(e, v) => {
        context.handleGetAllOrders(e)
      }} defaultCurrent={context.getPage}
        total={1000} /></div>
    </>
  );
};
export default Index;