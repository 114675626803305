import React, { useState, useContext } from 'react';
import Layout2 from "../Layout/Index"
import { PlusOutlined } from '@ant-design/icons';
import Container from '@mui/material/Container';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input , message , Select } from 'antd';
import Table from "./Table";
import Add from "./Add";
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
const { Content } = Layout;

const { Search } = Input;

const Index = () => {
    const [open, setOpen] = useState(false);
    const context = useContext(MahabadContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [size, setSize] = useState('large'); // default is 'middle'
    const [getCategory, setCategory] = useState('comments'); // default is 'middle'
    const onSearch = (value) => handleSearchResturant(value);
    const handleSearchResturant = async (value) => {
        context.setTableLoading(true)
        await axios.get(`${Config.adminApi}/returants/getOne/${value}`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            context.setAllResturants(res.data)
            context.setTableLoading(false)
        }).catch((err) => {
            context.handleGetAllResturants()
            context.setTableLoading(false)
        })
    }
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const handleGiveProxyToUser = async () => {
        context.setTableLoading(true)
        await axios.get(`${Config.adminApi}/giveProxytoUser`, {
          headers: {
            "x-auth-token": JSON.parse(context.getCookie("adminToken"))
          }
        }).then(res => {
          context.setDeleteAlert(false)
          context.handleGetAllProxies()
          messageApi.open({
            type: 'success',
            content: 'پروکسی ها با موفقیت بین کاربران پخش شدند',
          });
          context.setTableLoading(false)
        }).catch((err) => {
          context.setDeleteAlert(false)
          context.setTableLoading(false)
          messageApi.open({
            type: 'error',
            content: 'پروکسی ها بین کاربران پخش نشدند',
          });
        })
      }
    return (
        <Layout2>
            {contextHolder}
            <Container maxWidth="xl">
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                            direction: "rtl"
                        }}
                    >
                        <Breadcrumb.Item>خانه</Breadcrumb.Item>
                        <Breadcrumb.Item>دسته بندی ها</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                <Row gutter={[16, 16]} justify="between">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Select
      defaultValue={context.getType1}
      style={{
        width: "100%",
      }}
      onChange={(e)=>{
        context.setType1(e)
        context.handleGetAllCategories(1,e)
      }}
      options={[
        {
          value: 'posts',
          label: 'برای پست های اینستاگرام',
        },
        {
          value: 'comments',
          label: 'برای کامنت های اینستاگرام',
        }
      ]}
    />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Button onClick={showDrawer} type="primary" icon={<PlusOutlined />} size={size}>
                            افزودن
                        </Button>
                    </div>
                </Col>
                </Row>
                   
                </Col>
            </Row>
            <Content
                style={{
                    marginTop: '20px',
                }}
            >

                <div
                    style={{
                        marginBottom: 20
                    }}
                >

                    <Add open={open} onClose={onClose} />

                    <Table />
                </div>
            </Content>
            </Container>

        </Layout2>

    );
};
export default Index;