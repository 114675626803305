import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
let fileList = [
];


const Index = ({ open, onClose, category }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPhoto, setPhoto] = useState("");

  const handleEditeCategory = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': category.name,
      'type': category.type
    }
    await axios.put(`${Config.adminApi}/updateCategory/${category._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: 'دسته بندی با موفقیت ویرایش شد',
      });
      context.handleGetAllCategories(1,context.getType1)
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'دسته بندی ویرایش نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش دسته بندی"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleEditeCategory} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >
        
        <Form layout="vertical" hideRequiredMark>
          <p>نام دسته بندی</p>
          <Input onChange={(e) => {
           category.name = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(category) ? (category.name) : ("نام دسته بندی را وارد کنید")} />
                   <p>استفاده برای</p>
          <Select
      defaultValue={category.type}
      style={{
        width: "100%",
      }}
      onChange={(e)=>{
        category.type = e
      }}
      options={[
        {
          value: 'posts',
          label: 'برای پست های اینستاگرام',
        },
        {
          value: 'comments',
          label: 'برای کامنت های اینستاگرام',
        }
      ]}
    />
         
        </Form>
      </Drawer>
    </>
  );
};
export default Index;