import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getName, setName] = useState("");
  const [getUsername, setUsername] = useState("");
  const [getPassword, setPassword] = useState("");
  const [getLoading, setLoading] = useState(false);
  const [getIp, setIp] = useState(null);
  const [getPort, setPort] = useState(null);

  const handleAddAdmin = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': getUsername,
      'username': getUsername,
      'password': getPassword
    }
    await axios.post(`${Config.adminApi}/create`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.handleGetAllAdmins()
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'success',
        content: 'ادمین با موفقیت افزوده شد',
      });
    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      console.log(err)
      messageApi.open({
        type: 'error',
        content: err.response.data.message,
      });
    })
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن ادمین"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddAdmin} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>نام ادمین</p>
          <Input onChange={(e) => {
            setName(e.target.value)
            context.validator.current.showMessageFor("name");
          }} prefix={<FontColorsOutlined />} status={(getName.length < 3) ? ("error") : (null)} placeholder="نام ادمین را وارد کنید" />
          <p>نام کاربری ادمین</p>
          <Input onChange={(e) => {
            setUsername(e.target.value)
            context.validator.current.showMessageFor("username");
          }} prefix={<FontColorsOutlined />} status={(getUsername.length < 3) ? ("error") : (null)} placeholder="نام ادمین را وارد کنید" />
          <p>رمز عبور</p>
          <Input type="password" onChange={(e) => {
            setPassword(e.target.value)
            context.validator.current.showMessageFor("password");
          }} prefix={<FontColorsOutlined />} status={(getPassword.length < 3) ? ("error") : (null)} placeholder="رمز عبور ادمین را وارد کنید" />
          
        </Form>
      </Drawer>
    </>
  );
};
export default Index;