import React, { useEffect, useContext, useState } from 'react';
import { Button, Divider, Upload, Drawer, Form, Input, message, Select, Space, Checkbox } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
let fileList = [
];
const CheckboxGroup = Checkbox.Group;


const Index = ({ open, onClose, resturant }) => {
  const context = useContext(MahabadContext)
  const plainOptions = (context.getAllCategories) ? (context.getAllCategories.map(item => item.name)) : ("در حال بارگذاری");
  const defaultCheckedList = (resturant) ? (resturant.categories.map(item => item.name)) : ("در حال بارگذاری");
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

  const onChange = (list) => {
    console.log(list)
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };
  const changeCategoriesForStore = async()=>{
    let body = {
      "categories" : checkedList
    }
    await axios.post(`${Config.adminApi}/store/updateCategories/${resturant._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: "دسته بندی کسب و کار با موفقیت تغییر یافت",
      });
      context.handleGetAllResturants()
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: "مشکلی در تغییر دسته بندی کسب و کار وجود دارد",
      });
    })
  }

  useEffect(() => {
    context.handleGetAllCategoriesForEdite()
  }, [])
  return (
    <>
      {contextHolder}
      <Drawer
        title="دسته بندی کسب و کار"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={changeCategoriesForStore} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >

        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          انتخاب همه
        </Checkbox>
        <Divider />
        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
      </Drawer>
    </>
  );
};
export default Index;