import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getName, setName] = useState(null);
  const [getType, setType] = useState(null);
  const [getLoading, setLoading] = useState(false);

  const handleAddCategory = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': getName,
      'type': context.getType1
    }
    await axios.post(`${Config.adminApi}/createCategory`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.handleGetAllCategories(1,context.getType1)
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'success',
        content: 'دسته بندی با موفقیت افزوده شد',
      });
    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'دسته بندی اضافه نشد',
      });
    })
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن دسته بندی"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddCategory} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>نام دسته بندی</p>
          <Input onChange={(e) => {
            setName(e.target.value)
            context.validator.current.showMessageFor("ip");
          }} prefix={<FontColorsOutlined />} placeholder="نام دسته بندی را وارد کنید" />
          <p>استفاده برای</p>
          <Select
      defaultValue={context.getType1}
      style={{
        width: "100%",
      }}
      onChange={(e)=>{
        context.setType1(e)
      }}
      options={[
        {
          value: 'posts',
          label: 'برای پست های اینستاگرام',
        },
        {
          value: 'comments',
          label: 'برای کامنت های اینستاگرام',
        }
      ]}
    />
        </Form>
      </Drawer>
    </>
  );
};
export default Index;