import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
let fileList = [
];


const Index = ({ open, onClose, user }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPhoto, setPhoto] = useState("");

  const handleEditeUser = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'username': user.username,
      'password': user.password,
      'active': user.active,
      'proxy_detected_ip': user.proxy_detected_ip,
      'proxy_detected_port': user.proxy_detected_port,
      'quality': user.quality
    }
    await axios.put(`${Config.adminApi}/updateUser/${user._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: 'کاربر با موفقیت ویرایش شد',
      });
      context.handleGetAllUsers()
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'کاربر ویرایش نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش کاربر"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleEditeUser} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >
        
        <Form layout="vertical" hideRequiredMark>

          {/* <p><Switch defaultChecked={user.active} onChange={(e) => {
            user.active = e
          }} /> وضعیت کاربر</p> */}
          <p>نام</p>
          <Input onChange={(e) => {
           user.username = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(user) ? (user.username) : ("نام کاربری را وارد کنید")} />
          <p>رمز عبور</p>
          <Input onChange={(e) => {
           user.password = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(user) ? (user.password) : ("رمز عبور را وارد کنید")} />
          <p>آیپی پروکسی</p>
          <Input onChange={(e) => {
           user.proxy_detected_ip = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(user) ? (user.proxy_detected_ip) : ("آیپی پروکسی را وارد کنید")} />
          <p>پورت پروکسی</p>
          <Input onChange={(e) => {
           user.proxy_detected_port = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(user) ? (user.proxy_detected_port) : ("پورت پروکسی را وارد کنید")} />
           <p>کیفیت</p>
                        <Select
                defaultValue={user.quality}
                style={{
                  width: "100%",
                }}
                onChange={(e, v) => {
                  if(v.value !== "null"){
                  user.quality = v.value
                  }
                 
                }}
                options={[{ value: "null", label: "انتخاب کنید" }, { value: "top1", label: "ربات درجه 1" }, { value: "top2", label: "ربات درجه 2" }, { value: "bot", label: "ربات درجه 3" }]}
              />
        </Form>
      </Drawer>
    </>
  );
};
export default Index;