import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
let fileList = [
];


const Index = ({ open, onClose, admin }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPhoto, setPhoto] = useState("");

  const handleEditeAdmin = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': admin.name,
      'username': admin.username,
      'password': admin.password
    }
    await axios.put(`${Config.adminApi}/updateAdmin/${admin._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: 'ادمین با موفقیت ویرایش شد',
      });
      context.handleGetAllAdmins()
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'ادمین ویرایش نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش ادمین"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleEditeAdmin} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >
        
        <Form layout="vertical" hideRequiredMark>
          <p>نام ادمین</p>
          <Input onChange={(e) => {
            admin.name = e.target.value
            context.validator.current.showMessageFor("name");
          }} prefix={<FontColorsOutlined />} status={(admin.name.length < 3) ? ("error") : (null)} placeholder={admin.name} />
          <p>نام کاربری ادمین</p>
          <Input onChange={(e) => {
               admin.username = e.target.value
            context.validator.current.showMessageFor("username");
          }} prefix={<FontColorsOutlined />} status={(admin.username.length < 3) ? ("error") : (null)} placeholder={admin.username} />
          <p>رمز عبور</p>
          <Input type="password" onChange={(e) => {
            admin.password = e.target.value
            context.validator.current.showMessageFor("password");
          }} prefix={<FontColorsOutlined />} status={(admin.password.length < 3) ? ("error") : (null)} placeholder={admin.password} />
          
        </Form>
      </Drawer>
    </>
  );
};
export default Index;