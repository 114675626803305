import React, { useEffect, useContext, useState } from 'react';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
const { TextArea } = Input;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getQuestion, setQuestion] = useState(null);
  const [getAwnser, setAwnser] = useState(null);
  const [getLoading, setLoading] = useState(false);

  const handleAddFaq = async () => {
    if(getQuestion !== null && getAwnser !== null){
      setLoading(true);
      context.setTableLoading(true)
      let body = {
        'question': getQuestion,
        'awnser': getAwnser
      }
      await axios.post(`${Config.adminApi}/CreateFaq`, body, {
        headers: {
          "x-auth-token": JSON.parse(context.getCookie("adminToken"))
        }
      }).then(res => {
        context.handleGetPlans()
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'success',
          content: 'سوال متداول با موفقیت افزوده شد',
        });
      }).catch((err) => {
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'error',
          content: 'سوال متداول اضافه نشد',
        });
      })
    }else{
      messageApi.open({
        type: 'error',
        content: 'سوال متداول اضافه نشد فیلد های ضروری را ارسال کنید',
      });
    }
   
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن سوال متداول"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddFaq} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>سوال متداول</p>
          <TextArea onChange={(e) => {
            setQuestion(e.target.value)
            context.validator.current.showMessageFor("question");
          }} placeholder="سوال متداول را وارد کنید" rows={4} />
          <p>جواب سوال متداول</p>
          <TextArea onChange={(e) => {
            setAwnser(e.target.value)
            context.validator.current.showMessageFor("question");
          }} placeholder="جواب سوال متداول را وارد کنید" rows={4} />
        </Form>
      </Drawer>
    </>
  );
};
export default Index;