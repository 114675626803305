import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getPlanName, setPlanName] = useState("");
  const [getPlanCount, setPlanCount] = useState(0);
  const [getPlanPrice, setPlanPrice] = useState(0);
  const [getLoading, setLoading] = useState(false);
  const [getIp, setIp] = useState(null);
  const [getPort, setPort] = useState(null);

  const handleAddResturant = async () => {
    if(getPlanName !== "null" && getPlanCount !== 0 && getPlanPrice !== 0){
      setLoading(true);
      context.setTableLoading(true)
      let body = {
        'name': getPlanName,
        'count': getPlanCount,
        'price': getPlanPrice,
      }
      await axios.post(`${Config.adminApi}/createPlans`, body, {
        headers: {
          "x-auth-token": JSON.parse(context.getCookie("adminToken"))
        }
      }).then(res => {
        context.handleGetPlans()
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'success',
          content: 'پلن با موفقیت افزوده شد',
        });
      }).catch((err) => {
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'error',
          content: 'پلن اضافه نشد',
        });
      })
    }else{
      messageApi.open({
        type: 'error',
        content: 'پلن اضافه نشد فیلد های ضروری را ارسال کنید',
      });
    }
   
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن پلن سکه ای"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddResturant} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>نام پلن</p>
          <Input type="text" onChange={(e) => {
            setPlanName(e.target.value)
          }} prefix={<FontColorsOutlined />} status={(getPlanName.length < 3) ? ("error") : (null)} placeholder="نام پلن را وارد کنید" />
          <p>تعداد سکه</p>
          <Input type="number" onChange={(e) => {
           setPlanCount(e.target.value)
          }} prefix={<FontColorsOutlined />} status={(getPlanCount < 50) ? ("error") : (null)} placeholder="تعداد سکه را وارد کنید" />
          <p>قیمت پلن</p>
          <Input type="number" onChange={(e) => {
            setPlanPrice(e.target.value)
          }} prefix={<FontColorsOutlined />} status={(getPlanPrice < 50000) ? ("error") : (null)} placeholder="قیمت پلن را وارد کنید" />
        </Form>
      </Drawer>
    </>
  );
};
export default Index;