import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import ImgCrop from 'antd-img-crop';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleUploadUserFile = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      "file" :fileList[0].response
    }
    await axios.post(`${Config.adminApi}/ImportUsers`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.handleGetAllProxies()
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'success',
        content: 'کاربران با موفقیت افزوده شدند',
      });
    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'کاربران اضافه نشدن',
      });
    })
  }
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  return (
    <>
      {contextHolder}
      <Drawer
        title="وارد کردن از طریق فایل"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleUploadUserFile} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>انتخاب فایل txt</p>
      <Upload
        action={Config.adminApi + "/addPhoto"}
        listType="picture-card"
        name="photo"
        fileList={fileList}
        onChange={onChange}
      >
        {fileList.length < 5 && '+ Upload'}
      </Upload>
        </Form>
      </Drawer>
    </>
  );
};
export default Index;