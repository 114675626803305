import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
const { TextArea } = Input;


const Index = ({ open, onClose, comment }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPhoto, setPhoto] = useState("");

  const handleEditeComment = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'text': comment.text,
      'category': comment.category
    }
    await axios.put(`${Config.adminApi}/updateComment/${comment._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: 'کامنت با موفقیت ویرایش شد',
      });
      context.handleGetAllComments(comment.category,1)
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'کامنت ویرایش نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش کامنت"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleEditeComment} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >
        
        <Form layout="vertical" hideRequiredMark>
                    <p>کامنت مورد نظر را وارد کنید</p>
          <TextArea onChange={(e) => {
            comment.text = e.target.value
            context.validator.current.showMessageFor("ip");
          }} placeholder={(comment) ? (comment.text) : ("کامنت خود را وارد کنید")} rows={4} />
                   <p>استفاده برای</p>
                   <Select
      defaultValue={comment.category}
      style={{
        width: "100%",
      }}
      onChange={(e)=>{
        comment.category = e
      }}
      options={context.getCommentCategories !== null ? context.getCommentCategories.map(item=> ({value : item.name , label : item.name})) : []}
    />
         
        </Form>
      </Drawer>
    </>
  );
};
export default Index;