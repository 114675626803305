import { createContext } from "react";

const MahabadContext = createContext({
  getCategories: String,
  setCategories: ()=>{},
  setAdminPassword: ()=>{},
  handleAdminLogin: ()=>{},
  setCookie: ()=>{},
  getCookie: ()=>{},
  getInfluencerLoginData:Object,
  setInfluencerLoginData:()=>{},
  handleInfluencerLogin:()=>{},
  handleAddAttributes:()=>{},
  getAllCategories:[],
  getPostCategories:[],
  setAllCategories:()=>{},
  getifo:Object,
  setProjectId:()=>{},
  validator:()=>{},
  getifo:()=>{},
  getInfluencer:Object,
  setInfluencer:()=>{},
  onAddToBasket:()=>{},
  handleGetAllCategories:()=>{},
  handleInfluencerGetFalsePayments:()=>{},
  handleInfluencerGetTruePayments:()=>{},
  getInfluencerFalsePayments:Object,
  getInfluencerFalsePayments:Object,
  
});

export default MahabadContext;
