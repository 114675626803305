import React, { useContext, useEffect, useState } from 'react';
import { FundViewOutlined, GlobalOutlined, PartitionOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import Menu2 from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MahabadContext from '../../Context/MainContext';
import Config from '../../Config/config.json';
import { ConfigProvider } from 'antd';
import ChangePassword from './ChangePassword'
import { Dropdown } from 'antd';
import { Avatar, Space } from 'antd';
import faIR from 'antd/locale/fa_IR';

const items = [
    {
        label: (
            <Link to="/orders">
                سفارشات
            </Link>
        ),
        key: '/orders',
        icon: <UserOutlined />
    },
    {
        label: (
            <Link to="/users">
                کاربران
            </Link>
        ),
        key: '/users',
        icon: <UserOutlined />
    },
    {
        label: (
            <Link to="/plans">
                پلن ها
            </Link>
        ),
        key: '/plans',
        icon: <UserOutlined />
    },
    {
        label: (
            <Link to="/faq">
             سوالات متداول
            </Link>
        ),
        key: '/faq',
        icon: <UserOutlined />
    },
    {
        label: (
            <Link to="/payments">
                پرداخت ها
            </Link>
        ),
        key: '/payments',
        icon: <UserOutlined />
    },
    {
        label: (
            <Link to="/admins">
                ادمین ها
            </Link>
        ),
        key: '/admins',
        icon: <UserOutlined />
    },
    {
        label: (
            <Link to="/category">
                دسته بندی ها
            </Link>
        ),
        key: '/category',
        icon: <PartitionOutlined />
    },
    {
        label: (
            <Link to="/comments">
              کامنت ها
            </Link>
        ),
        key: '/comments',
        icon: <PartitionOutlined />
    }
];


const App = ({ children }) => {
    const context = useContext(MahabadContext)
    const [messageApi, contextHolder] = message.useMessage();
    const pathname = window.location.pathname; // in case user visits the path directly. The BottomNavBar is able to follow suit.
    const [value, setValue] = React.useState(pathname);
    const [collapsed, setCollapsed] = useState(false);
    const { Header, Content, Sider } = Layout;
    const [open1, setOpen1] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const showDrawer = () => {
        setOpen1(true);
    };

    const onClose = () => {
        setOpen1(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    useEffect(() => {
        // context.handleGetSettings()
        if (!context.getCookie("adminToken") || context.getCookie("adminToken") === "" || context.getCookie("adminToken") === "null") {
            window.location.assign('/admin/login')
        }
    }, [])
    return (
        <ConfigProvider locale={faIR}>
            <Layout style={{ direction: "rtl", minHeight: '100vh' }} className="site-layout" theme="dark">
                <Sider reverseArrow theme="dark" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    {/* <div style={{ textAlign: "center", padding: "5px" }}>{(context.getSettings !== null) ? (<img style={{ height: "32px", width: "32px" }} src={Config.adminPics + context.getSettings.logo} />) : (<img style={{ height: "32px", width: "32px" }} src={window.origin + "/Admin/images/logo.svg"} />)}</div> */}
                    <Menu style={{
                        position: 'sticky',
                        top: 0,
                    }} theme="dark" defaultSelectedKeys={[value]} mode="inline" items={items} />
                </Sider>
                <Layout>
                    <Header

                        style={{
                            padding: 0,
                            minHeight: 80,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div>
                            <p style={{ fontSize: "18px", textAlign: "center", color: "darkgoldenrod",marginRight:"30px"}}>پنل جم فالوور</p>
                        </div>
                        <div>
                            <Avatar onClick={handleClick} shape="square" style={{ marginLeft: "15px", cursor: "pointer" }} size={32} icon={<UserOutlined />} />
                            <Menu2
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>{JSON.parse(context.getCookie("admin")).name}</MenuItem>
                                <MenuItem onClick={() => {
                                    showDrawer()
                                    handleClose()
                                }}>تغییر رمز عبور</MenuItem>
                                <MenuItem onClick={context.handleLogOutAdmin}>خروج</MenuItem>
                            </Menu2>
                        </div>
                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                            borderRadius: "10px"
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
                <ChangePassword open1={open1} onClose={onClose} />
            </Layout>

        </ConfigProvider>
    );
};
export default App;