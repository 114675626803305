import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
const { TextArea } = Input;
const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLink, setLink] = useState(null);
  const [getQuantity, setQuantity] = useState(null);
  const [getUserInfo, setUserInfo] = useState(null);
  const [getType, setType] = useState(null);
  const [getType1, setType1] = useState("null");
  const [getLoading, setLoading] = useState(false);

  const handleAddOrder = async () => {
    if(getType !== null){
      setLoading(true);
      context.setTableLoading(true)
      let body = {
        'serviceId': getType._id,
        'serviceName': getType.name,
        'link': getLink,
        'quantity': getQuantity,
        'categoryForComment': getType1,
      }
      await axios.post(`${Config.adminApi}/createOrder`, body, {
        headers: {
          "x-auth-token": JSON.parse(context.getCookie("adminToken"))
        }
      }).then(res => {
        context.handleGetAllOrders()
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'success',
          content: 'سفارش با موفقیت افزوده شد',
        });
      }).catch((err) => {
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'error',
          content: 'سفارش اضافه نشد',
        });
      })
    }else{
      messageApi.open({
        type: 'error',
        content: 'اطلاعات رو بصورت کامل ارسال کنید',
      });
    }
    
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن سفارش"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddOrder} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>لینک مورد نظر را وارد کنید</p>
          <Input onChange={(e) => {
            setLink(e.target.value)
            context.validator.current.showMessageFor("link");
          }} placeholder="لینک را وارد کنید" type="text" />
          <p>تعداد مورد نظر را وارد کنید</p>
          <Input onChange={(e) => {
            setQuantity(e.target.value)
            context.validator.current.showMessageFor("quantity");
          }} placeholder="تعداد را وارد کنید" type="number" />
          <p>سرویس</p>
          <Select
      defaultValue={"انتخاب کنید"}
      style={{
        width: "100%",
      }}
      onChange={(e,v)=>{
        let foundedService = context.getAllServices.find(item=> item._id === e)
        if(foundedService){
          setType(foundedService)

        }
      }}
      options={context.getAllServices !== null ? [{value : "انتخاب کنید" , label : "انتخاب کنید"} , ...context.getAllServices.map(item=> ({value : item._id , label : item.name}))] : []}
    />
    {(getType !== null) ? ((getType.type === "createCommentPostOrder") ? (
      <>
        <p>پکیج کامنت برای استفاده از کامنت های دیفالت</p>
          <Select
      defaultValue={"انتخاب کنید"}
      style={{
        width: "100%",
      }}
      onChange={(e)=>{
        setType1(e)
      }}
      options={context.getCommentCategories !== null ? [{value : "انتخاب کنید" , label : "انتخاب کنید"} , ...context.getCommentCategories.map(item=> ({value : item.name , label : item.name}))] : []}
    />
      </>
    ) : (null)) : (null)}
        
        </Form>
      </Drawer>
    </>
  );
};
export default Index;