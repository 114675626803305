import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getUsername, setUsername] = useState("");
  const [getPassword, setPassword] = useState("");
  const [getQuality, setQuality] = useState("bot");
  const [getLoading, setLoading] = useState(false);
  const [getIp, setIp] = useState(null);
  const [getPort, setPort] = useState(null);

  const handleAddResturant = async () => {
    if(getQuality !== "null" && getUsername !== "" && getPassword !== ""){
      setLoading(true);
      context.setTableLoading(true)
      let body = {
        'username': getUsername,
        'password': getPassword,
        'quality': getQuality,
      }
      await axios.post(`${Config.adminApi}/createUser`, body, {
        headers: {
          "x-auth-token": JSON.parse(context.getCookie("adminToken"))
        }
      }).then(res => {
        context.handleGetAllUsers()
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'success',
          content: 'کاربر با موفقیت افزوده شد',
        });
      }).catch((err) => {
        setLoading(false);
        context.setTableLoading(false)
        onClose()
        messageApi.open({
          type: 'error',
          content: 'کاربر اضافه نشد',
        });
      })
    }else{
      messageApi.open({
        type: 'error',
        content: 'کاربر اضافه نشد فیلد های ضروری را ارسال کنید',
      });
    }
   
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن کاربر"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddResturant} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>نام کاربر</p>
          <Input onChange={(e) => {
            setUsername(e.target.value)
            context.validator.current.showMessageFor("name");
          }} prefix={<FontColorsOutlined />} status={(getUsername.length < 3) ? ("error") : (null)} placeholder="نام کاربر را وارد کنید" />
          <p>رمز عبور</p>
          <Input onChange={(e) => {
            setPassword(e.target.value)
            context.validator.current.showMessageFor("website");
          }} prefix={<FontColorsOutlined />} status={(getPassword.length < 3) ? ("error") : (null)} placeholder="رمز عبور کاربر را وارد کنید" />
          {/* <p>آیپی پروکسی</p>
          <Input onChange={(e) => {
            setIp(e.target.value)
            context.validator.current.showMessageFor("ip");
          }} prefix={<FontColorsOutlined />} status={(getPassword.length < 3) ? ("error") : (null)} placeholder="آیپی پروکسی کاربر را وارد کنید" />
          <p>پورت پروکسی</p>
          <Input onChange={(e) => {
            setPort(e.target.value)
            context.validator.current.showMessageFor("port");
          }} prefix={<FontColorsOutlined />} status={(getPassword.length < 3) ? ("error") : (null)} placeholder="پورت پروکسی کاربر را وارد کنید" /> */}
          <p>کیفیت</p>
                        <Select
                defaultValue={"انتخاب کنید"}
                style={{
                  width: "100%",
                }}
                onChange={(e, v) => {
                  if(v.value !== "null"){
                    setQuality(v.value)
                  }
                 
                }}
                options={[{ value: "null", label: "انتخاب کنید" }, { value: "top1", label: "ربات درجه 1" }, { value: "top2", label: "ربات درجه 2" }, { value: "bot", label: "ربات درجه 3" }]}
              />
        </Form>
      </Drawer>
    </>
  );
};
export default Index;