import React, { useState, useRef } from 'react';
import axios from "axios";
import MahabadContext from "./Context/MainContext";
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Config from './Config/config.json';


let ToastifyOptions = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const GlobalState = props => {
    const [, forceUpdate] = useState();
    SimpleReactValidator.addLocale('fa', {
        accepted: ':attribute مورد قبول قرار نگرفت',
        after: ':attribute باید تاریخی بعد از :date باشد.',
        after_or_equal: ':attribute باید تاریخی برابر یا بعد از :date باشد.',
        alpha: ":attribute باید شامل حروف انگلیسی باشد.",
        alpha_space: ":attribute باید شامل حروف انگلیسی و فاصله باشد.",
        alpha_num: ":attribute باید شامل اعداد و حروف انگلیسی باشد.",
        alpha_num_space: ":attribute باید شامل اعداد و حروف انگلیسی و فاصله باشد.",
        alpha_num_dash: ":attribute باید شامل اعداد و حروف انگلیسی و خط تیره.",
        alpha_num_dash_space: ":attribute باید شامل اعداد و حروف انگلیسی، خط تیره و فاصله باشد.",
        array: ":attribute باید یک آرایه باشد.",
        before: ":attribute باید قبل از :date باشد.",
        before_or_equal: ":attribute باید قبل یا برابر با :date باشد.",
        between: ":attribute باید بین :min و :max:type باشد.",
        boolean: ":attribute باید یک بول باشد.",
        card_exp: ":attribute باید یک تاریخ انقضا معتبر باشد.",
        card_num: ":attribute باید یک شماره کارت اعتباری معتبر باشد.",
        currency: ":attribute باید یک ارز معتبر باشد.",
        date: ":attribute باید تاریخ باشد.",
        date_equals: ":attribute باید در :date بازه باشد.",
        email: ":attribute بایدآدرس ایمیل معتبر باشد.",
        in: ":attribute باید یکی از :values باشد.",
        integer: ":attribute باید یک عدد صحیح باشد.",
        max: ":attribute باید کمتر از :max:type باشد.",
        min: ":attribute باید بیشتر از :min:type باشد.",
        not_in: ":attribute نباید مشابه با :values باشد.",
        not_regex: ":attribute نباید با الگوی مورد نیاز مطابقت داشته باشد.",
        numeric: ":attribute باید عدد باشد.",
        phone: ":attribute باید یک شماره تلفن معتبر باشد.",
        regex: ":attribute باید با الگوی مورد نیاز مطابقت داشته باشد.",
        required: "وارد کردن :attribute الزامی است.",
        size: ":attribute باید تبدیل شود به size:type:.",
        string: ":attribute باید یک رشته باشد.",
        typeof: ":attribute نوع صحیح :type نوع نیست.",
        url: ":attribute باید یک آدرس اینترنتی باشد.",
    });
    const validator = useRef(
        new SimpleReactValidator({
            locale: 'fa',
            element: message => <div style={{ color: "#ff9e9e", fontSize: "14px" }}>{message}</div>
        })
    );
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const [getLoading, setLoading] = useState(false);
    const [getTableLoading, setTableLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [getAdminUsername, setAdminUsername] = useState(null);
    const [getSettings, setSettings] = useState(null);
    const [getAdmin, setAdmin] = useState(null);
    const [getAdminPassword, setAdminPassword] = useState(null);
    const [getCaptchaVerifyed, setCaptchaVerifyed] = useState(null);
    const [getAllFaqs, setAllFaqs] = useState(null);
    const [getPage, setPage] = useState(1);
    const [getAllUsers, setAllUsers] = useState([]);
    const [getAllAdmins, setAllAdmins] = useState([]);
    const [getAllOrders, setAllOrders] = useState([]);
    const [getAllProviders, setAllProviders] = useState([]);
    const [getAllServices, setAllServices] = useState([]);
    const [getAllStores, setAllStores] = useState(null);
    const [getDeleteAlert, setDeleteAlert] = useState(false);
    const [getAllProxies, setAllProxies] = useState(null);
    const [getAllCategories, setAllCategories] = useState(null);
    const [getAllPlans, setAllPlans] = useState(null);
    const [getCommentCategories, setCommentCategories] = useState(null);
    const [getPostCategories, setPostCategories] = useState(null);
    const [getAllComments, setAllComments] = useState(null);
    const [getAllProfiles, setAllProfiles] = useState(null);
    const [getAllPosts, setAllPosts] = useState(null);
    const [getType1, setType1] = useState("posts");
    const [getType2, setType2] = useState(null);
    const [getType3, setType3] = useState(null);
    const [getAllPayments, setAllPayments] = useState(null);
    // const handleGetIncome = async () => {
    //     let ss = 0
    //     await axios.get(`${Config.adminApi}/getIncome`).then(res => {
    //       console.log(res.data)

    //     })
    //   }
    const handleGetAllUsers = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/users/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllUsers(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setTableLoading(false)
            setPage(value)
        })
    }
    const handleGetPlans = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/plans/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllPlans(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setTableLoading(false)
            setPage(value)
        })
    }
    
    const handleGetFAQS = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/faqs/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllFaqs(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setTableLoading(false)
            setPage(value)
        })
    }
    
    const handleGetAllAdmins = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/admins/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllAdmins(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setAllAdmins(null)
            setTableLoading(false)
            setPage(value)
        })
    }
    const handleGetAllOrders = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/orders/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllOrders(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setTableLoading(false)
            setPage(value)
            setAllOrders([])
        })
    }
    
    const handleGetAllPayments = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/payments/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllPayments(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setTableLoading(false)
            setPage(value)
        })
    }
    const handleGetAllProviders = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/providers/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllProviders(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            console.log(ex)
            setAllProviders([])
            setTableLoading(false)
        })
    }
    const handleGetAllServices = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/services/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllServices(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setAllServices([])
            setTableLoading(false)
        })
    }

    const handleGetAllStores = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/stores/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllStores(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            console.log(ex)
            setTableLoading(false)
        })
    }
    const handleGetAllProfiles = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/profiles/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllProfiles(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch((ex) => {
            setAllProfiles(null)
            console.log(ex)
            setTableLoading(false)
        })
    }
    const handleGetAllProxies = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/proxies/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllProxies(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch(ex => {
            console.log(ex)
            setTableLoading(false)
        })
    }

    const handleGetAllCategories = async (value, type) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        let count = 10
        await axios.get(`${Config.adminApi}/categories/${value}/${count}/${type}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllCategories(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch(ex => {
            console.log(ex)
            setAllCategories(null)
            setTableLoading(false)
        })
    }
    const handleGetAllCategoriesForComments = async (value) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        let count = 10
        await axios.get(`${Config.adminApi}/categories/${value}/${count}/comments`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setCommentCategories(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch(ex => {
            console.log(ex)
            setCommentCategories(null)
            setTableLoading(false)
        })
    }
    const handleGetAllCategoriesForPosts = async (value) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        let count = 10
        await axios.get(`${Config.adminApi}/categories/${value}/${count}/posts`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setPostCategories(res.data)
            handleGetAllPosts(res.data[0].name, 1)
            setTableLoading(false)
            setPage(value)
        }).catch(ex => {
            setPostCategories(null)
            setTableLoading(false)
        })
    }
    const handleGetAllComments = async (type, value) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        let count = 10
        await axios.get(`${Config.adminApi}/comments/${type}/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllComments(res.data)

            setTableLoading(false)
            setPage(value)
        }).catch(ex => {
            console.log(ex)
            setAllComments(null)
            setTableLoading(false)
        })
    }
    const handleGetAllPosts = async (type, value) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        let count = 10
        await axios.get(`${Config.adminApi}/posts/${type}/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllPosts(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch(ex => {
            console.log(ex)
            setAllPosts(null)
            setTableLoading(false)
        })
    }

    const _isAdmin = async () => {
        if (getCookie("adminToken") && JSON.parse(getCookie("adminToken")) != null) {
            return (true)
        } else {
            return (false)
        }
    }
    const _isRestaurant = async () => {
        if (getCookie("restaurantToken") && JSON.parse(getCookie("restaurantToken")) != null) {
            return (true)
        } else {
            return (false)
        }
    }
    const handleLoginAdmin = async () => {
        if (getCaptchaVerifyed == null) {
            if (validator.current.fieldValid("نام کاربری") && validator.current.fieldValid("رمز عبور")) {
                let body = {
                    "username": getAdminUsername,
                    "password": getAdminPassword
                }
                await axios.post(`${Config.adminApi}/login`, body).then(res => {
                    setCookie("adminToken", JSON.stringify(res.headers["x-auth-token"]), 365)
                    setCookie("admin", JSON.stringify(res.data), 365)
                    toast.success('ورود با موفقیت انجام شد', ToastifyOptions);
                    window.location.assign('/users')
                }).catch(err => {
                    swal({
                        title: "ناموفق",
                        text: err.response.data.message,
                        icon: "error",
                        button: "بستن"
                    });
                })
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }

        } else {
            swal({
                title: "ناموفق",
                text: "گزینه ی من ربات نیستم را بدرستی ارسال کنید",
                icon: "error",
                button: "بستن"
            });
        }
    }
    const handleLogOutAdmin = async () => {
        setCookie("adminToken", null, 365)
        window.location.assign("/admin/login")
    }

    const handleGetSettings = async () => {
        await axios.get(`${Config.userApi}/settings`).then(res => {
            setSettings(res.data)
            console.log(res.data)
        })
    }
    const store = {
        getAllFaqs:getAllFaqs,setAllFaqs,handleGetFAQS,getAllPayments:getAllPayments,setAllPayments,handleGetAllPayments,getAllPlans:getAllPlans,setAllPlans,handleGetPlans,handleGetAllAdmins, getAllAdmins: getAllAdmins, setAllAdmins, handleGetAllProfiles, getAllProfiles: getAllProfiles, setAllProfiles, getAllServices: getAllServices, setAllServices, handleGetAllServices, handleGetAllProviders, getAllProviders: getAllProviders, setAllProviders, handleGetAllOrders, getAllOrders: getAllOrders, setAllOrders, getType3: getType3, setType3, handleGetAllPosts, getAllPosts: getAllPosts, setAllPosts, handleGetAllCategoriesForPosts, getPostCategories: getPostCategories, setPostCategories, getType2: getType2, setType2, getCommentCategories: getCommentCategories, setCommentCategories, handleGetAllCategoriesForComments, handleGetAllComments, getAllComments: getAllComments, setAllComments, getType1: getType1, setType1, handleGetAllCategories, getAllCategories: getAllCategories, setAllCategories, getAllProxies: getAllProxies, setAllProxies, handleGetAllProxies, getDeleteAlert: getDeleteAlert, setDeleteAlert, getTableLoading: getTableLoading, setTableLoading, handleGetAllStores, getAllStores: getAllStores, setAllStores, getAllUsers: getAllUsers, setAllUsers, handleGetAllUsers, handleLogOutAdmin, getAdmin: getAdmin, setAdmin, handleGetSettings, getSettings: getSettings, setSettings, _isRestaurant, _isAdmin, getCaptchaVerifyed: getCaptchaVerifyed, setCaptchaVerifyed, setCookie, getCookie, handleLoginAdmin, getAdminUsername: getAdminUsername, setAdminUsername, getAdminPassword: getAdminPassword, setAdminPassword, getLoading, getLoading, setLoading, collapsed: collapsed, setCollapsed, validator,

    };

    return (
        <MahabadContext.Provider value={store}>
            {props.children}
        </MahabadContext.Provider>
    );
};

export default GlobalState;

