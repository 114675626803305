import React, { useState, useContext, useEffect } from 'react';
import Layout2 from "../Layout/Index"
import { PlusOutlined } from '@ant-design/icons';
import Container from '@mui/material/Container';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input , message , Select } from 'antd';
import Table from "./Table";
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
const { Content } = Layout;

const { Search } = Input;

const Index = () => {
    const [open, setOpen] = useState(false);
    const context = useContext(MahabadContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [size, setSize] = useState('large'); // default is 'middle'
    const [getCategory, setCategory] = useState('comments'); // default is 'middle'
    const onSearch = (value) => handleSearchOrder(value);
    const handleSearchOrder = async (value) => {
        context.setTableLoading(true)
        let body = {
            search : value
        }
        await axios.post(`${Config.adminApi}/searchOrder`,body, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            context.setAllOrders(res.data)
            context.setTableLoading(false)
        }).catch((err) => {
            context.handleGetAllOrders()
            context.setTableLoading(false)
        })
    }
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout2>
            {contextHolder}
            <Container maxWidth="xl">
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                            direction: "rtl"
                        }}
                    >
                        <Breadcrumb.Item>خانه</Breadcrumb.Item>
                        <Breadcrumb.Item>پرداخت ها</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Content
                style={{
                    marginTop: '20px',
                }}
            >

                <div
                    style={{
                        marginBottom: 20
                    }}
                >

                    <Table />
                </div>
            </Content>
            </Container>

        </Layout2>

    );
};
export default Index;