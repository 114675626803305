import * as React from "react";
import * as ReactDOM from "react-dom";
import Category from './Admin/Category/Index';
import Orders from './Admin/Orders/Index';
import Users from './Admin/Users/Index';
import Admins from './Admin/Admins/Index';
import Comments from './Admin/Comments/Index';
import Payments from './Admin/Payments/Index';
import Faq from './Admin/Faq/Index';
import Setting from './Admin/Setting/Index';
import Plans from './Admin/Plans/Index';
import Login from './Admin/Login/Index';


import './index.css';
import GlobalState from "./GlobalState";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/faq",
    element: <Faq />
  },
  {
    path: "/payments",
    element: <Payments />
  },
  {
    path: "/orders",
    element: <Orders />
  },
  {
    path: "/users",
    element: <Users />
  },
  {
    path: "/category",
    element: <Category />
  },
  {
    path: "/comments",
    element: <Comments />
  },
  {
    path: "/admin/login",
    element: <Login />
  },
  {
    path: "/admins",
    element: <Admins />
  },
  {
    path: "/plans",
    element: <Plans />
  },
  {
    path: "*",
    element: <Login />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <GlobalState>
<RouterProvider router={router} />
  </GlobalState>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
