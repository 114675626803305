import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
const { TextArea } = Input;


const Index = ({ open, onClose, order }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLink, setLink] = useState(null);
  const [getQuantity, setQuantity] = useState(null);
  const [getUserInfo, setUserInfo] = useState(null);
  const [getType, setType] = useState(context.getAllServices.find(item=> item._id === order.serviceId));
  const [getType1, setType1] = useState(null);
  const [getLoading, setLoading] = useState(false);
  
  const handleEditeComment = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'text': order.text,
      'category': order.category
    }
    await axios.put(`${Config.adminApi}/updateComment/${order._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: 'کامنت با موفقیت ویرایش شد',
      });
      context.handleGetAllComments(order.category,1)
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'کامنت ویرایش نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش کامنت"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            {/* <Button onClick={handleEditeComment} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button> */}
          </Space>
        }
      >
        
        <Form layout="vertical" hideRequiredMark>
          <p>لینک مورد نظر را وارد کنید</p>
          <Input onChange={(e) => {
            setLink(e.target.value)
            context.validator.current.showMessageFor("link");
          }} placeholder="لینک را وارد کنید" type="text" />
          <p>تعداد مورد نظر را وارد کنید</p>
          <Input onChange={(e) => {
            setQuantity(e.target.value)
            context.validator.current.showMessageFor("quantity");
          }} placeholder="تعداد را وارد کنید" type="number" />
          <p>سرویس</p>
          <Select
      defaultValue={order.serviceName}
      style={{
        width: "100%",
      }}
      onChange={(e,v)=>{
        let foundedService = context.getAllServices.find(item=> item._id === e)
        setType(foundedService)
      }}
      options={context.getAllServices !== null ? [{value : "انتخاب کنید" , label : "انتخاب کنید"} , ...context.getAllServices.map(item=> ({value : item._id , label : item.name}))] : []}
    />
    {(getType.type === "createCommentPostOrder") ? (
  <>
    <p>پکیج کامنت برای استفاده از کامنت های دیفالت</p>
      <Select
  defaultValue={getType.category}
  style={{
    width: "100%",
  }}
  onChange={(e)=>{
    setType1(e)
  }}
  options={context.getCommentCategories !== null ? [{value : "انتخاب کنید" , label : "انتخاب کنید"} , ...context.getCommentCategories.map(item=> ({value : item.name , label : item.name}))] : []}
/>
  </>
) : (null)}
        
        </Form>
      </Drawer>
    </>
  );
};
export default Index;