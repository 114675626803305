import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
let fileList = [
];


const Index = ({ open, onClose, plan }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPhoto, setPhoto] = useState("");

  const handleEditePlan = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': plan.name,
      'count': plan.count,
      'price': plan.price,
    }
    await axios.put(`${Config.adminApi}/updatePlan/${plan._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: 'پلن با موفقیت ویرایش شد',
      });
      context.handleGetPlans()
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'پلن ویرایش نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش پلن"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleEditePlan} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >
        
        <Form layout="vertical" hideRequiredMark>

          {/* <p><Switch defaultChecked={user.active} onChange={(e) => {
            user.active = e
          }} /> وضعیت کاربر</p> */}
          <p>نام</p>
          <Input onChange={(e) => {
           plan.name = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(plan) ? (plan.name) : ("نام پلن را وارد کنید")} />
          <p>تعداد سکه</p>
          <Input onChange={(e) => {
           plan.count = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(plan) ? (plan.count) : ("تعداد سکه را وارد کنید")} />
          <p>قیمت</p>
          <Input onChange={(e) => {
           plan.price = e.target.value
          }} prefix={<FontColorsOutlined />} placeholder={(plan) ? (plan.price) : ("قیمت پلن را وارد کنید")} />
        
        </Form>
      </Drawer>
    </>
  );
};
export default Index;