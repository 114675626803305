import React, { useContext, useEffect, useState } from 'react';
import Layout2 from "../Layout/Index"
import { InfoCircleOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { Tabs, Layout, Breadcrumb, theme, Row, Col, Input, Image, Typography, Tooltip, Button, message, Upload, Alert } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import Paper from '@mui/material/Paper';
import MahabadContext from '../../Context/MainContext';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const { Content } = Layout;

const { TextArea } = Input;
const { Title, Text } = Typography;

const fileList = [
];

const Index = () => {
    const context = useContext(MahabadContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [getSettings, setSettings] = useState(null);
    const [loadings, setLoadings] = useState(false);
    const [getItemsList, setItemsList] = useState([]);
    const handleGetSettings = async () => {
        setLoadings(true)
        await axios.get(`${Config.adminApi}/settings`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            setSettings(res.data)
            setLoadings(false)
        }).catch(err => {
            setLoadings(false)
            messageApi.open({
                type: 'error',
                content: "مشکلی در دریافت تنظیمات وجود دارد.",
            });
        })
    }
    const handleUpdateSettings = async () => {
        setLoadings(true)
        await axios.post(`${Config.adminApi}/updateSettings`, (getSettings), {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            setLoadings(false)
            messageApi.open({
                type: 'success',
                content: 'تنظیمات با موفقیت ویرایش شد',
            });
        }).catch(err => {
            setLoadings(false)
            messageApi.open({
                type: 'error',
                content: 'تنظیمات ویرایش نشد',
            });
        })
    }
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    useEffect(() => {
        handleGetSettings()
    }, [])
    return (
        <Layout2>
            {contextHolder}
            <Content
                style={{
                    margin: '10px',
                    direction: "ltr"
                }}
            >
                <div
                    style={{
                        marginBottom: 20
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '10px 0',
                            direction: "rtl"
                        }}
                    >
                        <Breadcrumb.Item>خانه</Breadcrumb.Item>
                        <Breadcrumb.Item>تنظیمات</Breadcrumb.Item>
                    </Breadcrumb>
                    
                {(getSettings === null) ? (null) : (    <>
                   <Paper sx={{ display: { xs: 'block', md : "none", sm:"block",lg: "none",xl:"none"} }} style={{boxShadow:"unset"}}>
                     <Row gutter={[16, 16]} justify="center" >
                        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                            <div style={{backgroundColor:"white",borderRadius:"10px",padding:"20px",marginBottom: "10px" }}>

                                <div style={{ textAlign: "right" }}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Tabs
                                                size="small"
                                                items={[
                                                    {
                                                        label: `تصاویر`,
                                                        key: 2,
                                                        children: (<Row gutter={[18, 18]}>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>لوگوی هدر سایز پیشنهادی (128*54)</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => {
                                                                         getSettings.headerLogo = e.file.response
                                                                        }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن لوگوی هدر</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>لوگوی فوتر سایز پیشنهادی (48*48)</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => { getSettings.footerLogo = e.file.response }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن لوگوی فوتر</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>بنر اول</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => {
                                                                         getSettings.bannerOne = e.file.response
                                                                        }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن بنر اول</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>بنر دوم</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => { getSettings.bannerTwo = e.file.response }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن بنر دوم</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                     
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                            
                                                            <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col>
                                                        </Row>),
                                                    },
                                                    {
                                                        label: `تماس با ما`,
                                                        key: 6,
                                                        children: (<Row gutter={[18, 18]}>
                                                            <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                <Text>شماره تماس با ما</Text>
                                                                <Input
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, websitePhone: e.target.value })
                                                                    }}
                                                                    value={getSettings.websitePhone}
                                                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                                                    suffix={
                                                                        <Tooltip title="شماره تماس با ">
                                                                            <InfoCircleOutlined
                                                                                style={{
                                                                                    color: 'rgba(0,0,0,.45)',
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    }
                                                                />
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                <Text>لینک اینستاگرام شما</Text>
                                                                <Input
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, instagram: e.target.value })
                                                                    }}
                                                                    value={getSettings.instagram}
                                                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                                                    suffix={
                                                                        <Tooltip title="لینک اینستاگرام شما">
                                                                            <InfoCircleOutlined
                                                                                style={{
                                                                                    color: 'rgba(0,0,0,.45)',
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    }
                                                                />
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <Text>آدرس شما</Text>
                                                                <TextArea
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, address: e.target.value })
                                                                    }}
                                                                    rows="10"
                                                                    value={getSettings.address}
                                                                />
                                                                <br />
                                                                <br />
                                                                <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col></Row>),
                                                    },
                                                    {
                                                        label: `نماد اعتماد`,
                                                        key: 7,
                                                        children: (<Row gutter={[18, 18]}>
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <Text>لینک اینماد</Text>
                                                                <p>
                                                                    برای قرار دادن لینک نماد اعتماد یا ساماندهی باید تگ استایل رو از لینک دریافتی اینماد یا ساماندهی حذف کنید و تگ عکس را ببینید برای مثال
                                                                    <br />
                                                                    <span style={{ color: "red" }}>حذف کنید : style="cursor: pointer; min-height: 150px;"</span>
                                                                    <br />
                                                                    <span style={{ color: "green" }}>با استفاده از / تک img رو ببندید</span>
                                                                </p>
                                                
                                                                <TextArea
                                                                    onChange={(e) => {
                                                                        console.log(e.target.value)
                                                                        setSettings({ ...getSettings, enamad: e.target.value })
                                                                    }}
                                                                    rows="3"
                                                                    value={getSettings.enamad}
                                                                />
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <Text>لینک ساماندهی</Text>
                                                                <p>
                                                                    برای قرار دادن لینک نماد اعتماد یا ساماندهی باید تگ استایل رو از لینک دریافتی اینماد یا ساماندهی حذف کنید و تگ عکس را ببینید برای مثال
                                                                    <br />
                                                                    <span style={{ color: "red" }}>حذف کنید : style="cursor: pointer; min-height: 150px;"</span>
                                                                    <br />
                                                                    <span style={{ color: "green" }}>با استفاده از / تک img رو ببندید</span>
                                                                </p>
                                                                <TextArea
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, samandehi: e.target.value })
                                                                    }}
                                                                    rows="3"
                                                                    value={getSettings.samandehi}
                                                                />
                                                                <br />
                                                                <br />
                                                                <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col></Row>),
                                                    },
                                                    {
                                                        label: `قوانین و مقررات`,
                                                        key: 8,
                                                        children: (<Row gutter={[18, 18]}>
                                                
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={getSettings.terms}
                                                                    onReady={editor => {
                                                                        // You can store the "editor" and use when it is needed.
                                                                        console.log("Editor is ready to use!", editor);
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        console.log({ event, editor, data });
                                                                        setSettings({ ...getSettings, terms: data })
                                                                    }}
                                                                />
                                                
                                                                <br />
                                                                <br />
                                                            <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col>
                                                            </Row>),
                                                    }
                                                ]}
                                            />
                                        </Col>

                                    </Row>
                                   
                                </div>


                            </div>
                        </Col>
                    </Row>
                   </Paper>
                   <Paper sx={{ display: { xs: 'none', md : "block", sm:"none",lg: "block",xl:"block"} }} style={{boxShadow:"unset"}}>
                     <Row gutter={[16, 16]} justify="center" >
                        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                            <div style={{backgroundColor:"white",borderRadius:"10px",padding:"20px",marginBottom: "10px" }}>

                                <div style={{ textAlign: "right" }}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Tabs
                                            tabPosition={"left"}
                                                size="small"
                                                items={[
                                                    {
                                                        label: `تصاویر`,
                                                        key: 2,
                                                        children: (<Row gutter={[18, 18]}>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>لوگوی هدر سایز پیشنهادی (128*54)</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => {
                                                                         getSettings.headerLogo = e.file.response
                                                                        }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن لوگوی هدر</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>لوگوی فوتر سایز پیشنهادی (48*48)</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => { getSettings.footerLogo = e.file.response }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن لوگوی فوتر</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>بنر اول</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => {
                                                                         getSettings.bannerOne = e.file.response
                                                                        }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن بنر اول</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={12} xl={12} sm={12}>
                                                                <p>بنر دوم</p>
                                                                <Upload
                                                                    action={`${Config.adminApi}/settings/addLogo`}
                                                                    listType="picture"
                                                                    defaultFileList={[...fileList]}
                                                                    className="upload-list-inline"
                                                                    name="logo"
                                                                    headers={{
                                                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                                                    }}
                                                                    onChange={(e) => { getSettings.bannerTwo = e.file.response }}
                                                                >
                                                                    <Button block icon={<UploadOutlined />}>وارد کردن بنر دوم</Button>
                                                                </Upload>
                                                                <br />
                                                                <br />
                                                            </Col>
                                                     
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                            
                                                            <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col>
                                                        </Row>),
                                                    },
                                                    {
                                                        label: `تماس با ما`,
                                                        key: 6,
                                                        children: (<Row gutter={[18, 18]}>
                                                            <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                <Text>شماره تماس با ما</Text>
                                                                <Input
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, websitePhone: e.target.value })
                                                                    }}
                                                                    value={getSettings.websitePhone}
                                                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                                                    suffix={
                                                                        <Tooltip title="شماره تماس با ">
                                                                            <InfoCircleOutlined
                                                                                style={{
                                                                                    color: 'rgba(0,0,0,.45)',
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    }
                                                                />
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                <Text>لینک اینستاگرام شما</Text>
                                                                <Input
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, instagram: e.target.value })
                                                                    }}
                                                                    value={getSettings.instagram}
                                                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                                                    suffix={
                                                                        <Tooltip title="لینک اینستاگرام شما">
                                                                            <InfoCircleOutlined
                                                                                style={{
                                                                                    color: 'rgba(0,0,0,.45)',
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    }
                                                                />
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <Text>آدرس شما</Text>
                                                                <TextArea
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, address: e.target.value })
                                                                    }}
                                                                    rows="10"
                                                                    value={getSettings.address}
                                                                />
                                                                <br />
                                                                <br />
                                                                <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col></Row>),
                                                    },
                                                    {
                                                        label: `نماد اعتماد`,
                                                        key: 7,
                                                        children: (<Row gutter={[18, 18]}>
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <Text>لینک اینماد</Text>
                                                                <p>
                                                                    برای قرار دادن لینک نماد اعتماد یا ساماندهی باید تگ استایل رو از لینک دریافتی اینماد یا ساماندهی حذف کنید و تگ عکس را ببینید برای مثال
                                                                    <br />
                                                                    <span style={{ color: "red" }}>حذف کنید : style="cursor: pointer; min-height: 150px;"</span>
                                                                    <br />
                                                                    <span style={{ color: "green" }}>با استفاده از / تک img رو ببندید</span>
                                                                </p>
                                                
                                                                <TextArea
                                                                    onChange={(e) => {
                                                                        console.log(e.target.value)
                                                                        setSettings({ ...getSettings, enamad: e.target.value })
                                                                    }}
                                                                    rows="3"
                                                                    value={getSettings.enamad}
                                                                />
                                                                <br />
                                                                <br />
                                                            </Col>
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <Text>لینک ساماندهی</Text>
                                                                <p>
                                                                    برای قرار دادن لینک نماد اعتماد یا ساماندهی باید تگ استایل رو از لینک دریافتی اینماد یا ساماندهی حذف کنید و تگ عکس را ببینید برای مثال
                                                                    <br />
                                                                    <span style={{ color: "red" }}>حذف کنید : style="cursor: pointer; min-height: 150px;"</span>
                                                                    <br />
                                                                    <span style={{ color: "green" }}>با استفاده از / تک img رو ببندید</span>
                                                                </p>
                                                                <TextArea
                                                                    onChange={(e) => {
                                                                        setSettings({ ...getSettings, samandehi: e.target.value })
                                                                    }}
                                                                    rows="3"
                                                                    value={getSettings.samandehi}
                                                                />
                                                                <br />
                                                                <br />
                                                                <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col></Row>),
                                                    },
                                                    {
                                                        label: `قوانین و مقررات`,
                                                        key: 8,
                                                        children: (<Row gutter={[18, 18]}>
                                                
                                                            <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={getSettings.terms}
                                                                    onReady={editor => {
                                                                        // You can store the "editor" and use when it is needed.
                                                                        console.log("Editor is ready to use!", editor);
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        console.log({ event, editor, data });
                                                                        setSettings({ ...getSettings, terms: data })
                                                                    }}
                                                                />
                                                
                                                                <br />
                                                                <br />
                                                            <Button loading={loadings} onClick={handleUpdateSettings}  type="primary" block>ثبت</Button>
                                                            </Col>
                                                            </Row>),
                                                    }
                                                ]}
                                            />
                                        </Col>

                                    </Row>
                                   
                                </div>


                            </div>
                        </Col>
                    </Row>
                   </Paper></>)}

                </div>
            </Content>
        </Layout2>

    );
};
export default Index;