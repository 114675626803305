import React, { useEffect, useContext, useState } from 'react';
import { EyeOutlined, DeleteOutlined, DownloadOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal, Typography, Table, message, Pagination, Image, Col, Row } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import Edite from "./Edite";
const { Text, Link } = Typography;




const Index = () => {
  const context = useContext(MahabadContext)
  const [getCategory, setCategory] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [openEdite, setOpenEdite] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const showDrawerEdite = () => {
    setOpenEdite(true); 
  };

  const onCloseEdite = () => {
    setOpenEdite(false);
  };
  const onShowCategories = () => {
    setOpenCategories(true);
  };

  const onCloseCategories = () => {
    setOpenCategories(false);
  };
  let columns = [
    {
      title: 'نام',
      dataIndex: 'name',
    },
    {
      title: 'نوع',
      dataIndex: 'type',
    },
    {
      title: 'مشاهده',
      key: '_id',
      dataIndex: '_id',
      value: '_id',
      fixed: 'right',
      width: 100,
      render: (id) => <Tooltip title="مشاهده دسته بندی">
        <Button onClick={(e) => {
          setCategory(context.getAllCategories.find(item => item._id == id))
          showDrawerEdite(true)
        }} type="primary" shape="circle" icon={<EyeOutlined />} />
      </Tooltip>,
    },
    {
      title: 'حذف',
      key: '_id',
      dataIndex: '_id',
      value: '_id',
      fixed: 'right',
      width: 100,
      render: (id) => <Tooltip title="حذف دسته بندی">
        <Button onClick={(e) => {
          setCategory(context.getAllCategories.find(item => item._id == id))
          context.setDeleteAlert(true)
        }} type="primary" danger shape="circle" icon={<DeleteOutlined />} />
      </Tooltip>,
    },
  ];
  const handleDeleteCategory = async (id) => {
    context.setTableLoading(true)
    await axios.delete(`${Config.adminApi}/deleteCategory/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setDeleteAlert(false)
      context.handleGetAllCategories(1,context.getType1)
      messageApi.open({
        type: 'success',
        content: 'دسته بندی با موفقیت حذف شد',
      });
      context.setTableLoading(false)
    }).catch((err) => {
      context.setDeleteAlert(false)
      context.setTableLoading(false)
      messageApi.open({
        type: 'error',
        content: 'دسته بندی حذف نشد',
      });
    })
  }

  useEffect(() => {
    context.handleGetAllCategories(1,context.getType1)
  }, [])
  const tableProps = {
    loading: context.getLoading
  };
  return (
    <>
      {(openEdite === false) ? (null) : (<Edite category={getCategory} open={openEdite} onClose={onCloseEdite} />)}
      <Modal okType="danger" onOk={(e) => {
        handleDeleteCategory(getCategory._id)
      }} okText="تایید" cancelText="انصراف" style={{ direction: "rtl" }} title="شما در حال حذف دسته بندی هستید" open={context.getDeleteAlert} onCancel={() => { context.setDeleteAlert(false) }}>
        <p>آیا مطمئنید؟</p>
      </Modal>
      <div style={{ direction: "rtl" }}>{contextHolder}</div>
      <Table
        columns={columns}
        size="middle"
        tableLayout="auto"
        style={{ direction: "rtl" }}
        scroll={{
          x: 100
        }}
        loading={context.getTableLoading}
        pagination={false}
        dataSource={(context.getAllCategories !== null) ? context.getAllCategories : []}
      />
      <div style={{ marginTop: "15px" }}><Pagination onChange={(e, v) => {
        context.handleGetAllCategories(e,context.getType1)
      }} defaultCurrent={context.getPage}
        total={1000} /></div>
    </>
  );
};
export default Index;